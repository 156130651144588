import React from 'react';
import { motion } from "framer-motion"
import Header from './Header';

export default function Layout({ children }) {

  const page = {
    visible: { 
      opacity: 1,
      transition: {
        when:"beforeChildren",
        staggerChildren: 0.5,
      }
    },
    hidden: { 
      opacity: 0,
      transition: {
        when: "afterChildren",
      }
    },
  }

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={page}
    >
      <Header />
      <main>
        { children }
      </main>
      <footer className="h-footer text-center bg-primary flex relative font-heading">
        <div className="m-auto px-6">
          <small className="text-white text-lg tracking-wider">Copyright © 2020 Calabasas Films and Media LLC. All rights reserved</small>
        </div>
      </footer>
    </motion.div>
  )
}
